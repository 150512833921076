import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const opts = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#222665",
        secondary: "#ed860e",
        disabled: "#E0E0E0",
      },
    },
  },
};

export default new Vuetify(opts);
